var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c("h2", [_vm._v("轨迹设置")]),
      _c(
        "el-form",
        { attrs: { model: _vm.form, "label-width": "220px" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "工作日：" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    placeholder: "请选择",
                    multiple: "",
                    "collapse-tags": "",
                  },
                  model: {
                    value: _vm.form.work_day,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "work_day", $$v)
                    },
                    expression: "form.work_day",
                  },
                },
                _vm._l(_vm.Workingday, function (list) {
                  return _c("el-option", {
                    key: list.id,
                    attrs: { label: list.name, value: list.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "轨迹定位时间：" } },
            [
              _c("el-time-picker", {
                staticStyle: { width: "150px" },
                attrs: {
                  "value-format": "HH:mm:ss",
                  "picker-options": {
                    selectableRange: "06:00:00 - 20:00:00",
                  },
                  placeholder: "任意时间点",
                },
                model: {
                  value: _vm.form.start_time,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "start_time", $$v)
                  },
                  expression: "form.start_time",
                },
              }),
              _c("span", { staticStyle: { margin: "0px 10px" } }, [
                _vm._v("至"),
              ]),
              _c("el-time-picker", {
                staticStyle: { width: "150px" },
                attrs: {
                  "value-format": "HH:mm:ss",
                  "picker-options": {
                    selectableRange: "06:00:00 - 20:00:00",
                  },
                  placeholder: "任意时间点",
                },
                model: {
                  value: _vm.form.end_time,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "end_time", $$v)
                  },
                  expression: "form.end_time",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "启用轨迹的员工：" } },
            [
              _c("span", { staticClass: "font" }, [
                _vm._v(" " + _vm._s(_vm.userid.length) + " "),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleradduser(_vm.row)
                    },
                  },
                },
                [_vm._v("设置")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handlersave } },
                [_vm._v("保 存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("add-user", { ref: "user", on: { handlerids: _vm.handlerids } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
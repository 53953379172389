<!--
 * @Author: your name
 * @Date: 2021-01-26 15:09:23
 * @LastEditTime: 2021-05-08 17:03:02
 * @LastEditors: Please set LastEditors
 * @Description: 轨迹设置
 * @FilePath: \sd-vue-admin\src\views\setting\featuresManagement\track\index.vue
-->
<template>
  <div class="orderTest-container">
    <h2>轨迹设置</h2>
    <el-form :model="form" label-width="220px">
      <el-form-item label="工作日：">
        <el-select
          v-model="form.work_day"
          clearable
          placeholder="请选择"
          multiple
          collapse-tags
        >
          <el-option
            v-for="list in Workingday"
            :key="list.id"
            :label="list.name"
            :value="list.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="轨迹定位时间：">
        <el-time-picker
          v-model="form.start_time"
          value-format="HH:mm:ss"
          style="width: 150px"
          :picker-options="{
            selectableRange: '06:00:00 - 20:00:00',
          }"
          placeholder="任意时间点"
        ></el-time-picker>
        <span style="margin: 0px 10px">至</span>
        <el-time-picker
          v-model="form.end_time"
          value-format="HH:mm:ss"
          style="width: 150px"
          :picker-options="{
            selectableRange: '06:00:00 - 20:00:00',
          }"
          placeholder="任意时间点"
        ></el-time-picker>
      </el-form-item>
      <el-form-item label="启用轨迹的员工：">
        <span class="font">
          {{ userid.length }}
        </span>
        <el-button type="primary" @click="handleradduser(row)">设置</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handlersave">保 存</el-button>
      </el-form-item>
    </el-form>
    <add-user ref="user" @handlerids="handlerids"></add-user>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import AddUser from './components/AddUser.vue'
  export default {
    components: { AddUser },
    data() {
      return {
        form: {
          id: '',
          work_day: [],
          user_ids: 0,
          start_time: '',
          end_time: '',
        },
        userid: [],
        Workingday: [
          {
            id: '1',
            name: '周一',
          },
          {
            id: '2',
            name: '周二',
          },
          {
            id: '3',
            name: '周三',
          },
          {
            id: '4',
            name: '周四',
          },
          {
            id: '5',
            name: '周五',
          },
          {
            id: '6',
            name: '周六',
          },
          {
            id: '0',
            name: '周日',
          },
        ],
        url: {
          list: '/setAdmin/position/set-info',
          save: '/setAdmin/position/save',
        },
      }
    },

    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.handlerinfo()
    },
    methods: {
      handlersave() {
        let data = {}
        Object.assign(data, this.form)
        console.log(data, '元数据')
        data.work_day = data.work_day.join(',')
        data.user_ids = this.userid
        data.user_ids = data.user_ids.join(',')
        console.log(data, '元数据')
        postAction(this.url.save, data)
          .then((res) => {
            console.log(res)
            this.$message({
              message: res.msg,
              type: 'success',
            })
            this.handlerinfo()
          })
          .catch((err) => {})
      },
      handlerids(data) {
        console.log(data, 'dayin')
        this.userid = data
      },
      handlerinfo() {
        postAction(this.url.list, {})
          .then((res) => {
            console.log(res, '')
            this.form = res.data
            this.form.work_day = this.form.work_day.split(',')
            this.userid = res.data.user_ids.split(',')
            console.log(this.form.work_day, this.userid)
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handleradduser(row) {
        this.$refs.user.showuser = true
        this.$refs.user.handlreinfo(this.form.user_ids)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .orderTest-container {
    padding: 20px;
  }
  .font {
    margin-right: 20px;
    text-decoration: underline;
  }
  .font:hover {
    cursor: pointer;
  }
</style>
